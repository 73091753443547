<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" xl="4">
          <v-container class="px-0 text-center text-sm-start">
            <h1 class="title text-uppercase blueDark--text">{{ $t('newCompany') }}</h1>
            <p>{{ $t('createNewCompanyIntro') }}</p>

            <v-form ref="form" v-model="valid" @submit.prevent="promptCreateNewCompany">
              <v-text-field
                v-model="companyName"
                :label="$t('companyName')"
                :hint="$t('companyNameHelperText')"
                :rules="companyNameRules"
                :counter="100"
              />

              <div class="d-flex justify-center justify-sm-end py-3">
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                  :block="$vuetify.breakpoint.xsOnly"
                  :disabled="!valid || loading"
                  @click.prevent="promptCreateNewCompany"
                >
                  {{ $t('create') }}
                </v-btn>
              </div>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { isRequired, minimum, maximum } from '@/consts/inputRules';
import confirmDialog from '@/components/dialog/confirmDialog';
import api from '@/util/api';
import messageDialog from '@/components/dialog/messageDialog';
import router from '@/router';
import is from '@/lib/util/is';
import auth from '@/util/auth';
import genericErrorMessage from '@/util/genericErrorMessage';

const companyNameRules = [
  (name) => isRequired('name', name),
  (name) => minimum('name', name, 3),
  (name) => maximum('name', name, 100),
];

export default {
  name: 'NewCompany',

  data: () => ({
    valid: false,
    loading: false,
    companyName: '',
    companyNameRules,
  }),

  methods: {
    promptCreateNewCompany() {
      confirmDialog(
        this.$t('createNewCompany'),
        this.$t('createNewCompanyMsg', null, { companyName: this.companyName }),
        this.$t('cancel'),
        this.$t('create'),
      )
        .open()
        .then((confirmed) => {
          if (confirmed) {
            api
              .createNewCustomer(this.companyName)
              .then((res) => {
                messageDialog(
                  this.$t('success'),
                  this.$t('companyCreatedSuccessMsg', null, { companyName: this.companyName }),
                  this.$t('ok'),
                )
                  .open()
                  .then(() => {
                    router.push('/');
                    if (is.number(res.id)) {
                      auth.setCustomerId(res.id);
                    }
                    location.reload();
                  });
              })
              .catch(genericErrorMessage);
          }
        });
    },
  },
};
</script>
